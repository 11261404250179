import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactService } from '../contact.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public messageSent:boolean = false;
  public messageError:boolean = false;
  public submit:boolean = false;
  public showDetailsOrange:boolean = false;
  public showDetailsLemon:boolean = false;
  public showDetailsRedFruit:boolean = false;
  public contactForm:UntypedFormGroup = new UntypedFormGroup({});
  
  constructor(private contactService: ContactService, public fb:UntypedFormBuilder) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      email2: new UntypedFormControl('', [Validators.required]),
      tht: new UntypedFormControl(),
      code: new UntypedFormControl(''),
      message: new UntypedFormControl('', [Validators.required]),
    });
  }

  get name() { return this.contactForm.get('name'); }
  get email() { return this.contactForm.get('email'); }
  get email2() { return this.contactForm.get('email2'); }
  get tht() { return this.contactForm.get('tht'); }
  get code() { return this.contactForm.get('code'); }
  get message() { return this.contactForm.get('message'); }

  sendRequest()
  {
    this.submit = true;
    if (this.tht?.value=='') {
      this.tht?.setValue(null);
    }
    if (this.contactForm.valid && this.email?.value === this.email2?.value)
    {
      this.contactService.sendRequest(this.contactForm.value).subscribe(
        ()=>{
          this.messageSent = true;
          this.submit = false;
          // setTimeout(()=>{
          //   this.messageSent = false;
          // }, 5000);
        },
        ()=>{
          this.messageError = true;
          setTimeout(()=>{
            this.messageError = false;
          }, 5000);
        }
      );
    }
  }

  showDrawerOrange()
  {
    this.showDetailsOrange = true;
  }

  hideDrawerOrange()
  {
    this.showDetailsOrange = false;
  }

  showDrawerLemon()
  {
    this.showDetailsLemon = true;
  }

  hideDrawerLemon()
  {
    this.showDetailsLemon = false;
  }

  showDrawerRedFruit()
  {
    this.showDetailsRedFruit = true;
  }

  hideDrawerRedFruit()
  {
    this.showDetailsRedFruit = false;
  }
}
