import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private baseUrl = 'https://activationapi.azurewebsites.net';

  constructor(private http: HttpClient) { }

  sendRequest(payload: any) {
    return this.http.post(`${this.baseUrl}/limondaine/contact`, payload);
  }

}
