<div [hidden]="!showDetailsOrange" class="overlay" (click)="hideDrawerOrange()">
    <span class="close" (click)="hideDrawerOrange()">&times;</span>
    <div class="info">
        <h3>Voedingswaarden Orange</h3>
        <table class="table">
            <thead>
                <th></th>
                <th class="dose">per 100 ml</th>
            </thead>
            <tbody>
                <tr>
                    <td>Energie</td>
                    <td>6 kJ/1 kcal (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>Vetten </td>
                    <td>0 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>waarvan verzadigd vet</td>
                    <td>0 g (0%<span>&#42;</span>)</td>
                </tr><tr>
                    <td>Koolhydraten</td>
                    <td>0,1 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>waarvan suikers</td>
                    <td>0,1 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>Eiwit</td>
                    <td>0,01 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>Zout</td>
                    <td>0,002 g (0%<span>&#42;</span>)</td>
                </tr>
            </tbody>
        </table>
        <p><span>&#42;</span>Referentie-inname van een gemiddelde volwassene (8400 kJ / 2000 kcal).</p>
        <h3>Ingredienten Orange</h3>
        <p>
            Water, sinaasappelsap uit concentraat 1,5%, voedingszuur (citroenzuur), antioxidant (ascorbinezuur), aroma's, kleurstof (bètacaroteen), zoetstoffen (acesulfaam-K, sucralose).
        </p>
    </div>
</div>
<div [hidden]="!showDetailsLemon" class="overlay" (click)="hideDrawerLemon()">
    <span class="close" (click)="hideDrawerLemon()">&times;</span>
    <div class="info">
        <h3>Voedingswaarden Lemon</h3>
        <table class="table">
            <thead>
                <th></th>
                <th class="dose">per 100 ml</th>
            </thead>
            <tbody>
                <tr>
                    <td>Energie</td>
                    <td>4 kJ/1 kcal (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>Vetten </td>
                    <td>0 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>waarvan verzadigd vet</td>
                    <td>0 g (0%<span>&#42;</span>)</td>
                </tr><tr>
                    <td>Koolhydraten</td>
                    <td>0,1 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>waarvan suikers</td>
                    <td>0,1 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>Eiwit</td>
                    <td>0,00 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>Zout</td>
                    <td>0,003 g (0%<span>&#42;</span>)</td>
                </tr>
            </tbody>
        </table>
        <p><span>&#42;</span>Referentie-inname van een gemiddelde volwassene (8400 kJ / 2000 kcal).</p>
        <h3>Ingrediente Lemon</h3>
        <p>
            Water, citroensap uit concentraat 4,5%, voedingszuur (citroenzuur), antioxidant (ascorbinezuur), natuurlijk citroen-limoen aroma, zoetstoffen (acesulfaam-K, sucralose).
        </p>
    </div>
</div>
<div [hidden]="!showDetailsRedFruit" class="overlay" (click)="hideDrawerRedFruit()">
    <span class="close" (click)="hideDrawerRedFruit()">&times;</span>
    <div class="info">
        <h3>Voedingswaarden Red fruit</h3>
        <table class="table">
            <thead>
                <th></th>
                <th class="dose">per 100 ml</th>
            </thead>
            <tbody>
                <tr>
                    <td>Energie</td>
                    <td>5 kJ/1 kcal (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>Vetten </td>
                    <td>0 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>waarvan verzadigd vet</td>
                    <td>0 g (0%<span>&#42;</span>)</td>
                </tr><tr>
                    <td>Koolhydraten</td>
                    <td>0,0 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>waarvan suikers</td>
                    <td>0,0 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>Eiwit</td>
                    <td>0,00 g (0%<span>&#42;</span>)</td>
                </tr>
                <tr>
                    <td>Zout</td>
                    <td>0,003 g (0%<span>&#42;</span>)</td>
                </tr>
            </tbody>
        </table>
        <p><span>&#42;</span>Referentie-inname van een gemiddelde volwassene (8400 kJ / 2000 kcal).</p>
        <h3>Ingredienten Red fruit</h3>
        <p>
            Water, zwartebessensap uit concentraat 0,5%, wortelsap uit concentraat, voedingszuur (citroenzuur), antioxidant (ascorbinezuur), aroma, zoetstoffen (acesulfaam-K, sucralose).
        </p>
    </div>
</div>
<main>
    <header>
        <div class="grid">
            <div class="col-4-12 col-vert-center">
                <img src="/assets/images/limondaine_logo.png" alt="Limondaine logo"/>
            </div>
            <div class="col-8-12 col-end-center hide-on-mobile">
                <nav>
                    <ul>
                        <li><a href="#products">Producten</a></li>
                        <li><a href="#faq">FAQ</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
    <section class="banner">
        <div class="grid">
            <div class="col-5-12 col-vert-center">
                <div class="old">
                    <img class="old-bottles" src="/assets/images/limondaine_flessen.png">
                    <div class="title">
                        <h1>Nu in een nieuw jasje</h1>
                        <img class="arrow" src="/assets/images/limondaine_arrow.png">
                    </div>
                </div>
            </div>
            <div class="col-7-12">
                <img class="new-packaging" src="/assets/images/limondaine_pakken.png">
            </div>
        </div>
    </section>
    <section class="showcase" id="products">
        <div class="grid">
            <div class="col-1-1">
                <span>Ons</span>
                <h2>Assortiment</h2>
            </div>
        </div>
        <div class="grid">
            <div class="col-4-12 product">
                <img src="/assets/images/products/pak_orange.png"/>
                <h3>Orange</h3>
                <p>Limondaine Orange is een  koolzuurvrije limonade met de heerlijke smaak van sinaasappel. Het heeft een lekkere fruitige smaak én slechts 1kcal.</p>
                <div class="col-1-1 button">
                    <button (click)="showDrawerOrange()">Voedingswaarden</button>
                </div>
            </div>
            <div class="col-4-12 product">
                <img src="/assets/images/products/pak_lemon.png"/>
                <h3>Lemon</h3>
                <p>Limondaine Lemon is een  koolzuurvrije limonade met de heerlijke smaak van citroen. Het heeft een lekkere fruitige smaak én slechts 1kcal. </p>
                <div class="col-1-1 button">
                    <button (click)="showDrawerLemon()">Voedingswaarden</button>
                </div>
            </div>
            <div class="col-4-12 product">
                <img src="/assets/images/products/pak_red.png"/>
                <h3>Red fruit</h3>
                <p>Limondaine Red fruit is een  koolzuurvrije limonade met de heerlijke smaak van rood fruit. Het heeft een lekkere fruitige smaak én slechts 1kcal. </p>
                <div class="col-1-1 button">
                    <button (click)="showDrawerRedFruit()">Voedingswaarden</button>
                </div>
            </div>
        </div>
    </section>
    <section class="faq" id="faq">
        <div class="grid-small">
            <div class="col-1-1">
                <h2>Veelgestelde vragen</h2>
            </div>
        </div>
        <div class="grid-small">
            <div class="col-1-1">
                <details>
                    <summary><h4>Waar zijn de producten van Limondaine verkrijgbaar?</h4></summary>
                    <p>De producten van Limondaine zijn verkrijgbaar bij Jumbo. We streven ernaar om onze producten bij zoveel mogelijk verschillende supermarktketens in zoveel mogelijk winkels beschikbaar te stellen.</p>
                </details>
                <details>
                    <summary><h4>Heeft Limondaine een nieuwe verpakking?</h4></summary>
                    <p>Limondaine heeft na jaren een nieuw jasje gekregen! Met de kartonnen verpakkingen hebben we veel minder fossiel plastic vergeleken met de petflessen. Ook hebben we de receptuur nog lekkerder gemaakt! Hierdoor hebben we enorm veel suiker gereduceerd ten op zichte van de petflessen.</p>
                </details>
                <details>
                    <summary><h4>Hoe kunnen de producten van Limondaine het beste bewaard worden?</h4></summary>
                    <p>Onze houdbare producten kunnen het beste bewaard worden in een donkere en koele ruimte. Na opening dienen de producten gekoeld bewaard te worden (max. 7°C).</p>
                </details>
                <details>
                    <summary><h4>Zitten er gluten, lactose of andere allergenen in Limondaine?</h4></summary>
                    <p>Nee. Al onze producten zijn vrij van glutenbevattende granen, ei, pinda, noten, soja en melk (inclusief lactose).</p>
                </details>
            </div>
        </div>
    </section>
    <section class="contact" id="contact">
        <div class="grid-small">
            <div class="col-1-1">
                <h2>Contact</h2>
                <p class="center">Wil jij meer weten over onze producten of heb je een opmerking vul dan onderstaand formulier in of stuur een e-mail naar <a href="mailto:consumentenservice@riedel.nl">consumentenservice&#64;riedel.nl.</a></p>
                <form [formGroup]="contactForm">
                  <div class="field">
                      <div class="label">Naam<span>&#42;</span></div>
                      <input id="name" name="name" type="text" placeholder="Naam" formControlName="name" required/>
                      <div *ngIf="name?.invalid && (name?.dirty || name?.touched || submit)" class="error">Naam is verplicht</div>
                  </div>
                  <div class="field">
                      <div class="label">E-mail<span>&#42;</span></div>
                      <input id="email" type="email" placeholder="E-mail" formControlName="email" required/>
                      <div *ngIf="email?.invalid && (email?.dirty || email?.touched || submit)" class="error">E-mail is verplicht</div>
                  </div>
                  <div class="field">
                    <div class="label">Herhaal e-mail<span>&#42;</span></div>
                    <input id="email2" type="email" placeholder="Herhaal e-mail" formControlName="email2" required/>
                    <div *ngIf="email2?.invalid && (email2?.dirty || email2?.touched || submit)" class="error">E-mail is verplicht</div>
                    <div *ngIf="email?.valid && email2?.valid && email?.value != email2?.value" class="error">E-mail is ongelijk aan vorige</div>
                  </div>
                  <div class="field">
                    <div class="label">THT-datum (te vinden aan bovenzijde van de verpakking)</div>
                    <input id="tht" type="date" placeholder="THT-datum" formControlName="tht"/>
                  </div>
                    <div class="field">
                    <div class="label">Productiecode (te vinden naast de THT-datum) </div>
                    <input id="code" type="text" placeholder="Productiecode" formControlName="code"/>
                  </div>
                  <div class="field">
                    <div class="label">Vraag/Opmerking<span>&#42;</span></div>
                    <textarea id="message" rows="10" placeholder="Vraag/Opmerking" formControlName="message" required></textarea>
                    <div *ngIf="message?.invalid && (message?.dirty || message?.touched || submit)" class="error">Vraag/Opmerking is verplicht</div>
                  </div>
                  <p class="center sent">Door op 'Verstuur' te drukken ga ik akkoord met het <a target="_blank" href="privacy">privacybeleid</a>.</p>
                  <div class="field">
                      <a [hidden]="this.messageSent" class="btn-red" (click)="sendRequest()">Verstuur</a>
                  </div>
                  <p *ngIf="messageSent" class="center sent">Succesvol verzonden.</p>
                  <p *ngIf="messageError" class="center sent red">Er is een fout opgetreden. Probeer het later nog eens.</p>
               </form>
            </div>
        </div>
    </section>
    <footer>
        <div class="grid">
            <div class="col-6-12 col-vert-center">
                <div class="footer-q">
                    <h2>Heb jij een vraag over ons product?</h2>
                    <div class="footer-buttons">
                        <a href="#faq" class="btn-red">Naar FAQ</a>
                        <a href="#contact" class="btn-white">Contact</a>
                    </div>
                </div>
            </div>
            <div class="col-6-12 col-vert-center footer-logo">
                <img src="/assets/images/limondaine_logo.png" alt="Limondaine logo"/>
            </div>
        </div>
    </footer>
    <div class="center privacy-footer"><a href="privacy">Privacybeleid</a></div>
</main>
