<main>
    <header>
        <div class="grid">
            <div class="col-4-12 col-vert-center">
                <a href="/">
                    <img src="/assets/images/limondaine_logo.png" alt="Limondaine"/>
                </a>
            </div>
            <div class="col-8-12 col-end-center hide-on-mobile">
                <nav>
                    <ul>
                        <li><a href="/#products">Producten</a></li>
                        <li><a href="/#faq">FAQ</a></li>
                        <li><a href="/#contact">Contact</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
    <section class="privacy">
        <h1>Privacybeleid</h1>
        <p>Lime Factory B.V. (hierna ook: “Lime Factory”, “wij”, “ons” of “onze”) host en beheert de webpagina: https://limondaine.nl (hierna: “Merkpagina”) van Limondaine.</p>
        <p>Deze pagina wordt door Lime Factory beheerd in opdracht van Riedel B.V. te Ede.</p>
        <p>In het kader daarvan verwerkt Lime Factory persoonsgegevens van de personen die deelnemen contact opnemen.</p>
        <p>Betrouwbaarheid is daarbij een belangrijke factor. Respect voor de privacy en een zorgvuldige omgang met persoonsgegevens is een belangrijk onderdeel van die betrouwbaarheid. 
        Wij willen transparant zijn over de wijze waarop wij, als verwerkingsverantwoordelijke, omgaan met de verwerking van (persoons)gegevens van Deelnemers van de Actie.</p>
        <p>In deze privacyverklaring lichten we dit verder toe, waarbij voorop staat dat we de wet- en regelgeving, zoals de ‘Algemene Verordening Gegevensbescherming (AVG)’, naleven.
            Daarnaast leggen we uit welke persoonsgegevens wij verzamelen en voor welke doeleinden wij deze gebruiken. We raden je aan deze verklaring zorgvuldig te lezen.</p>

        <p>Deze privacyverklaring is niet van toepassing op websites of diensten van derden, waaronder hyperlinks naar websites van anderen dan Lime Factory en social media buttons. Lime Factory is niet verantwoordelijk voor het privacybeleid en het gebruik van cookies op die websites en social media platforms.</p>

        <h3>Gebruik van persoonsgegevens</h3>
        <p>Via de Merkpagina worden gegevens verwerkt, die zowel direct als indirect herleidbaar kunnen zijn tot personen (al dan niet door de Deelnemer ter beschikking gesteld). Een zorgvuldige omgang met deze persoonsgegevens is van groot belang. Deze worden door ons dan ook zorgvuldig verwerkt en beveiligd. Bij onze verwerking houden wij ons aan de eisen die de toepasselijke wet- en regelgeving stelt. Dat betekent onder andere dat:</p>
            <ul>
                <li>wij duidelijk vermelden voor welke doeleinden wij persoonsgegevens verwerken. Dat doen we via deze privacyverklaring;</li>
                <li>wij onze verzameling van persoonsgegevens beperken tot alleen de persoonsgegevens die nodig zijn voor legitieme doeleinden;</li>
                <li>wij je in gevallen waarin jouw goedkeuring vereist is eerst vragen om toestemmingom jouw persoonsgegevens te verwerken;</li>
                <li>wij passende beveiligingsmaatregelen nemen om jouw persoonsgegevens te beschermen en die maatregelen ook eisen van partijen die onder onze verantwoordelijkheid persoonsgegevens verwerken;</li>
                <li>wij jou het recht geven om jouw persoonsgegevens op jouw aanvraag inzichtelijk te maken, te corrigeren, verwijderen of exporteren.</li>
            </ul>

        <h3>Rechtmatigheid van verwerking</h3>
        <p>De Deelnemer geeft toestemming voor de verwerking van de door de Deelnemer opgegeven persoonsgegevens in overeenstemming met deze privacyverklaring. De Deelnemer kan de gegeven toestemming altijd weer intrekken. Het intrekken van de toestemming tast de rechtmatigheid van de verwerking van persoonsgegevens vóór de intrekking niet aan.</p>
        <p>Voorgenoemde belangen zijn afgewogen tegen de belangen van de Deelnemer, waarbij specifiek rekening is gehouden met het recht op bescherming van hun persoonlijke levenssfeer. Hierin is onder meer meegewogen dat wij slechts een minimaal aantal persoonsgegevens verwerken.</p>

        <h3>Bewaartermijnen</h3>
        <p>Wij bewaren alle persoonsgegevens die wij in het kader van de Actie verwerken tot uiterlijk 3 maanden na afloop van de Actie. De Actie loopt op 11 juli 2021 af. Uiterlijk op 1 oktober 2021 zullen wij alle in het kader van de Actie door ons verwerkte persoonsgegevens verwijderen.</p>

        <h3>Jouw rechten</h3>
        <p>Je kunt ons altijd vragen om jou inzage te geven in de persoonsgegevens die wij van jou hebben verzameld. Tevens kun je ons verzoeken om jouw persoonsgegevens te wijzigen of te verwijderen.</p>
        <p>Daarnaast kun je bij ons een gegevensexport opvragen met daarin je persoonsgegevens die wij met jouw toestemming of in het kader van de uitvoering van de Actie verwerken. Ook heb je het recht om tegen de verwerking van jouw persoonsgegevens bezwaar te maken alsmede om ons te verzoeken om de verwerking van jouw persoonsgegevens (tijdelijk) te beperken.</p>
        <p>Om misbruik van jouw identiteit te voorkomen kunnen wij je vragen om je vooraf te identificeren.</p>

        <h3>Verstrekking aan derden</h3>
        <p>Wij kunnen jouw persoonsgegevens doorgeven aan de volgende partijen:</p>
        <ul>
            <li>partijen die betrokken zijn bij de productie, uitvoering en bezorging van de prijs;</li>
            <li>overheidsinstellingen, opsporingsinstanties en (toezichthoudende) autoriteiten, indien wij hiertoe een verzoek ontvangen of indien wij dit zelf noodzakelijk achten (bijvoorbeeld in verband met het opsporen van fraude);</li>
            <li>derden indien dit noodzakelijk is om de rechten van jou of die van ons te beschermen. Denk hierbij aan externe (juridische) adviseurs.</li>
        </ul>
        <p>Als je meedoet aan de actie dan delen wij onder meer persoonsgegevens met de volgende partijen:</p>
        <ul>
            <li>Van iedere Deelnemer die heeft aangegeven per e-mail op de hoogte te willen worden gehouden, wordt het e-maildres gedeeld met Riedel B.V. Nederland.</li>
            <li>Hosting en opslag gegevens Merkpagina: Microsoft Azure</li>
        </ul>

        <h3>Google Analytics</h3>
        Voor inzicht in het gebruik van de website gebruiken wij Google Analytics onder de volgende voorwaarden:
        <ul>
            <li>Lime Factory B.V. heeft een bewerkersovereenkomst met Google gesloten;</li>
            <li>Het laatste octet van het IP-adres wordt gemaskeerd;</li>
            <li>De optie 'gegevens delen' is uitgezet;</li>
            <li>Er geen gebruik wordt gemaakt van andere Google-diensten in combinatie met de Google Analytics-cookies.</li>
        </ul>

        <h3>Nieuwsbrieven</h3>
        <p>Lime Factory verstuurt geen nieuwsbrieven of andere digitale aanbiedingen aan de Deelnemer.</p>

        <h3>Beveiliging</h3>
        <p>Wij nemen passende beveiligingsmaatregelen om onder meer vernietiging, verlies, wijziging, ongeoorloofde verstrekking van of ongeoorloofde toegang tot persoonsgegevens te voorkomen en te beperken.</p>

        <h3>Websites van derden en app store</h3>
        <p>Deze privacyverklaring is niet van toepassing op websites van derden die door middel van links met onze website zijn verbonden. Wij kunnen niet garanderen dat deze derden op een betrouwbare of veilige manier met jouw persoonsgegevens omgaan. We raden je aan de privacyverklaring van deze websites te lezen voordat je van deze websites gebruikmaakt.</p>

        <h3>Wijzigingen in deze privacyverklaring</h3>
        <p>Wij behouden ons het recht voor om wijzigingen aan te brengen in deze privacyverklaring. We adviseren je dan ook om deze privacyverklaring geregeld te raadplegen, zodat je van deze wijzigingen op de hoogte bent.</p>

        <h3>Contact</h3>
        <p>Natuurlijk helpen wij je graag verder als je vragen of klachten hebt over de verwerking van jouw persoonsgegevens. Wij verzoeken je dan een e-mail te sturen naar info&#64;limefactory.nl. Op grond van de privacywetgeving heb je ook het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens.</p>

        <h3>Naam en contactgegevens</h3>
        <p>Voor vragen over deze privacyverklaring of overige vragen die betrekking hebben op privacy kun je altijd contact met ons opnemen via onderstaande contactgegevens.</p>
        <p>
        Lime Factory B.V.<br/>
        Keizersgracht 620, 1017 ER Amsterdam<br/>
        Telefoonnummer: +31(0)20 - 320 9002<br/>
        E-mailadres: info&#64;limefactory.nl<br/>
        </p>
    </section>
</main>
